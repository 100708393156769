<template>
  <NuxtLayout>
    <NuxtLoadingIndicator />
    <NuxtPage />
  </NuxtLayout>
</template>

<script setup lang="ts">
import "./cookie-bar";
useHead({
  link: [
    { rel: "icon", type: "image/x-icon", href: "/favicon.ico" }
  ]
});
</script>
