<template>
  <div class="text-center">
    <template v-if="error.statusCode === 404">
      <img src="~/assets/svg/error_pages/404.svg?url" alt="404 not found" class="error-image">
      <h1 class="mb-2 title mt-2">
        <b>Stránka nebyla nalezena</b>
      </h1>
    </template>
    <template v-if="error.statusCode === 500">
      <img src="~/assets/svg/error_pages/500.svg?url" alt="500 internal server error" class="error-image">
      <h1 class="h1">
        <b>Vyskytla se chyba</b>
      </h1>
    </template>
    <template v-if="error.statusCode === 502">
      <img src="~/assets/svg/error_pages/502.svg?url" alt="502 bad gateway" class="error-image">
      <h1 class="h1">
        <b>Server je momentálně nedostupný</b>
      </h1>
    </template>

    <span class="notice mb-3 m-auto text-center j-c-c">Prosím vraťte se na úvodní stranu.</span>
    <NuxtLink :to="localePath({name: 'index'})">
      <v-btn class="btn mb-5 btn--primary btn--icon arrow-left">
        Zpět na úvod
      </v-btn>
    </NuxtLink>
  </div>
</template>

<script setup lang="ts">
defineProps<{
  error: Record<string, unknown>
}>();
const localePath = useLocalePath();
</script>
