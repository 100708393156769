export default function () {
  const { prepareURLParams } = useUrlsUtils();

  return {
    COMPANY_GET_BY_CODE: (code: string) => prepareURLParams("/api/company/v1/get-by-code/{code}/", { code }),
    USER_REGISTER: () => "/api/user/v1/register/",
    USER_LOGIN: () => "/api/user/v1/login/",
    ADMIN_USER_LOGIN: () => "/api/user/v1/admin-login/",
    USER_FORGOTTEN_PASSWORD: () => "/api/user/v1/forgotten-password/",
    USER_LOGOUT: () => "/api/user/v1/logout/",
    USER_LOGOUT_ALL: () => "/api/user/v1/logout-all/",
    USER_OTP_VERIFY: () => "/api/user/v1/otp-verify/",
    USER_SEND_OTP: (userID: string | null) => prepareURLParams("/api/user/v1/send-otp/{userID}/", { userID }),
    USER_SAVE_PHONE_NUMBER: () => "/api/user/v1/save-phone/",
    USER_TOKEN_REFRESH: () => "/api/token/refresh/",
    USER_TOKEN_VERIFY: () => "/api/token/verify/",
    USER_INFO: () => "/api/user/v1/user-info/",
    BACKUP_CODES: () => "api/user/v1/backup-codes/",
    USER_CHANGE_PASSWORD: () => "/api/user/v1/change-password/",
    CHECK_PASSWORD: () => "/api/user/v1/check-password",
    MFA_SECRET_QR: () => "/api/user/v1/mfa-secret-qr/",
    USER_PDF_REPORT_DATA: (userID: string) => prepareURLParams("/api/pdf/v1/user-pdf-report-data/{userID}/", { userID }),
    SELF_PAYER_CERTIFICATE_DATA: (userID: string) => prepareURLParams("/api/pdf/v1/self-payer-certificate-data/{userID}/", { userID }),
    COMPANY_PDF_DATA: (id: string) => prepareURLParams("/api/pdf/v1/company-data/{id}/", { id }),
    INSURANCE_COMPANIES_LIST: () => "/api/company/v1/insurance-companies-list/"
  };
}
