import mitt, { type Emitter } from "mitt";

export const eventTypes = {
  REMINDER_DIALOG_OPEN: "reminderDialogOpen",
  SURVEY_NEXT_STEP: "surveyNextStep",
  ALL_VIDEOS_PAUSE: "allVideosPause",
  AUTH_REGISTER_USER: "auth:register-user"
} as const;

export type Events = {
  [eventTypes.REMINDER_DIALOG_OPEN]: boolean
  [eventTypes.SURVEY_NEXT_STEP]: void
  [eventTypes.ALL_VIDEOS_PAUSE]: void
  [eventTypes.AUTH_REGISTER_USER]: void
};

export default defineNuxtPlugin(() => {
  const emitter: Emitter<Events> = mitt<Events>();

  return {
    provide: {
      bus: {
        ...emitter,
        EVENT_TYPES: eventTypes
      }
    }
  };
});
