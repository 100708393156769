export default defineNuxtRouteMiddleware(async (to) => {
  const needAuth = to.meta?.auth ?? true;
  const appConfig = useAppConfig();
  const isAdminFE = appConfig.settings?.is_admin_fe ?? false;
  const { $localePath: localePath } = useNuxtApp();

  if (needAuth) {
    const authStore = useAuthStore();

    // @ts-ignore
    const isLoginPage = to.path === localePath({ name: "login" });

    if (!authStore.isLoggedIn) {
      await authStore.verifyToken();
    }

    if (needAuth && !authStore.isLoggedIn && !isLoginPage) {
      // @ts-ignore
      return navigateTo(localePath({ name: "login" }));
    }

    const verifyCompanyAdmin = to.meta?.verifyCompanyAdmin ?? false;
    if (verifyCompanyAdmin) {
      const userInfoStore = useUserInfoStore();
      if (!userInfoStore.isDataLoaded) {
        await userInfoStore.loadUserInfoData();
      }
      if (!userInfoStore.userData?.is_company_admin) {
        // @ts-ignore
        return navigateTo(localePath({ name: "index" }));
      }
    }

    if (isAdminFE) {
      const userInfoStore = useUserInfoStore();
      if (!userInfoStore.isDataLoaded) {
        await userInfoStore.loadUserInfoData();
      }
      if (!userInfoStore.userData?.is_superuser && !userInfoStore.userData?.is_account_manager && !userInfoStore.userData?.is_account_manager_supervisor) {
        // @ts-ignore
        return navigateTo(localePath({ name: "login" }));
      }
    }
  }
});
