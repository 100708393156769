import { defineStore, skipHydrate, acceptHMRUpdate } from "pinia";
import { StorageSerializers } from "@vueuse/core";
import * as Sentry from "@sentry/vue";

export const useAuthStore = defineStore("auth", () => {
  const apiUrls = useCommonApiUrls();
  const config = useRuntimeConfig();

  const tokens = useLocalStorage<JWTTokens | null>("authTokens", null, { serializer: StorageSerializers.object });
  const isLoggedIn = ref<boolean>(false);
  const _verifyRetry = ref<boolean>(false);

  /* This is used to store the selected auth provider for the register/login page
   * This is little hacky, because oAuth2 must be opened in a popup window, and we need to store the selected provider
  */
  const selectedAuthProvider = useLocalStorage<string | null>("selectedAuthProvider", null);
  const registerAuthData = useLocalStorage("registerAuthData", null, { serializer: StorageSerializers.object });

  function parseJwt (token: string) {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(window.atob(base64).split("").map(function (c) {
      return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(""));

    return JSON.parse(jsonPayload);
  }

  const accessTokenDefined = computed<boolean>(() => tokens.value?.access !== null && tokens.value?.access !== undefined);
  function accessTokenExpired () {
    if (!accessTokenDefined.value || tokens.value === null) { return true; }
    const token = parseJwt(tokens.value.access);
    return (Date.now() >= token.exp * 1000);
  }
  const refreshTokenDefined = computed<boolean>(() => tokens.value?.refresh !== null && tokens.value?.refresh !== undefined);
  function refreshTokenExpired () {
    if (!refreshTokenDefined.value || tokens.value === null) { return true; }
    const token = parseJwt(tokens.value.refresh);
    return (Date.now() >= token.exp * 1000);
  }

  async function verifyToken (): Promise<void> {
    if (!accessTokenDefined.value || tokens.value === null) {
      return;
    }
    if (accessTokenExpired() && !refreshTokenExpired()) {
      await refreshToken();
    }
    const { error } = await useApiCall(apiUrls.USER_TOKEN_VERIFY(), {
      method: "POST",
      body: {
        token: tokens.value.access
      }
    });

    if (error.value && error.value?.statusCode === 401 && error.value?.data.code === "token_not_valid") {
      if (!_verifyRetry.value) {
        _verifyRetry.value = true;
        await verifyToken();
      }
    } else if (!error.value) {
      isLoggedIn.value = true;
      _verifyRetry.value = false;
    }
  }

  async function refreshToken () : Promise<boolean> {
    if (tokens.value === null) { return false; }
    const { data } = await useFetch<JWTTokens>(apiUrls.USER_TOKEN_REFRESH(), {
      baseURL: config.public.baseApiUrl,
      // @ts-ignore
      method: "POST",
      body: {
        refresh: tokens.value.refresh
      }
    });
    if (data.value) {
      // @ts-ignore
      tokens.value.access = data.value.access;
      return true;
    }
    return false;
  }

  async function logout () : Promise<void> {
    if (tokens.value === null) { return; }
    const { error } = await useApiCall(apiUrls.USER_LOGOUT(), {
      method: "POST",
      body: {
        refresh_token: tokens.value.refresh
      }
    });

    if (!error.value || (error.value?.statusCode === 401 && error.value?.data.code === "token_not_valid")) {
      clearUserSession();
    } else {
      // eslint-disable-next-line no-console
      console.log(error.value, error.value?.data);
    }
  }

  function clearUserSession () {
    tokens.value = null;
    isLoggedIn.value = false;
    Sentry.setUser(null);
  }

  return {
    tokens: skipHydrate(tokens),
    isLoggedIn,
    accessTokenExpired,
    refreshTokenExpired,
    refreshToken,
    verifyToken,
    logout,
    clearUserSession,
    selectedAuthProvider: skipHydrate(selectedAuthProvider),
    registerAuthData: skipHydrate(registerAuthData)
  };
});

// make sure to pass the right store definition
if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useAuthStore, import.meta.hot));
}
