import { merge } from "lodash-es";

export default async function <DataT> (url: string, options: object = {}) {
  const { $i18n } = useNuxtApp();
  // @ts-ignore
  const locale = $i18n.locale.value;
  const authStore = useAuthStore();
  const config = useRuntimeConfig();
  let internalOptions: object = { headers: { "Accept-Language": locale } };

  if (authStore.accessTokenExpired() && !authStore.refreshTokenExpired()) {
    await authStore.refreshToken();
  }

  if (authStore.isLoggedIn && authStore.tokens !== null) {
    internalOptions = merge(internalOptions, { headers: { Authorization: `Bearer ${authStore.tokens.access}` } });
  }
  const mergedOptions = merge(internalOptions, options);
  return useFetch<DataT>(url, {
    ...mergedOptions,
    baseURL: config.public.baseApiUrl
  });
}
