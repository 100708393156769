import { default as departmentsqdqOxAwHffMeta } from "/app/src/pages/company/departments.vue?macro=true";
import { default as employee_45settingsiBotCfaBDfMeta } from "/app/src/pages/company/employee-settings.vue?macro=true";
import { default as indexPbDDEhGvAcMeta } from "/app/src/pages/company/index.vue?macro=true";
import { default as resultsRwGSehcFXsMeta } from "/app/src/pages/company/results.vue?macro=true";
import { default as settingsu0Qj0yX5ewMeta } from "/app/src/pages/company/settings.vue?macro=true";
import { default as companytWIBTdJjXhMeta } from "/app/src/pages/company.vue?macro=true";
import { default as _91token_93TfP2itXTLrMeta } from "/common_fe/src/pages/forgotten_password/[uid]/[token].vue?macro=true";
import { default as index8GQijcUZslMeta } from "/common_fe/src/pages/forgotten_password/index.vue?macro=true";
import { default as indexdPwi6hPdMfMeta } from "/app/src/pages/index.vue?macro=true";
import { default as indexSG0VHSSa7FMeta } from "/common_fe/src/pages/login/index.vue?macro=true";
import { default as mental_45health_45careLwRuqHTCHyMeta } from "/app/src/pages/mental-health-care.vue?macro=true";
import { default as here_45i_45amEgaYxNJ4k2Meta } from "/app/src/pages/my-results/here-i-am.vue?macro=true";
import { default as how_45and_45what_45i_45do3jDuIiMZLJMeta } from "/app/src/pages/my-results/how-and-what-i-do.vue?macro=true";
import { default as indexKKVElqLjMDMeta } from "/app/src/pages/my-results/index.vue?macro=true";
import { default as my_45resultsmBgC3VpbnpMeta } from "/app/src/pages/my-results.vue?macro=true";
import { default as need_45helpVDIi4enQ7QMeta } from "/app/src/pages/need-help.vue?macro=true";
import { default as payment_45finishWcM0sTYirzMeta } from "/app/src/pages/payment-finish.vue?macro=true";
import { default as privacy_policy_selfpayer8jHSgAWRCJMeta } from "/common_fe/src/pages/privacy_policy_selfpayer.vue?macro=true";
import { default as privacy_policykyJiQYDx4HMeta } from "/common_fe/src/pages/privacy_policy.vue?macro=true";
import { default as anonymity_45settingsVsmDOJh1dzMeta } from "/app/src/pages/profile/anonymity-settings.vue?macro=true";
import { default as demography_45datazux458MXsHMeta } from "/app/src/pages/profile/demography-data.vue?macro=true";
import { default as indexSJNeMkG0qzMeta } from "/app/src/pages/profile/index.vue?macro=true";
import { default as personal_45dataBcf6Sa37nWMeta } from "/app/src/pages/profile/personal-data.vue?macro=true";
import { default as security_45settings5OUm4Y7e5RMeta } from "/app/src/pages/profile/security-settings.vue?macro=true";
import { default as profileTx35vCKuH1Meta } from "/app/src/pages/profile.vue?macro=true";
import { default as indexflND2zZ1Z4Meta } from "/common_fe/src/pages/registration/index.vue?macro=true";
import { default as demographyjJ2p5TnCZ7Meta } from "/app/src/pages/survey/demography.vue?macro=true";
import { default as dimension_45resultGraBCGuXCIMeta } from "/app/src/pages/survey/dimension-result.vue?macro=true";
import { default as finishhzIbu2ifTTMeta } from "/app/src/pages/survey/finish.vue?macro=true";
import { default as indexzKW1J69z7OMeta } from "/app/src/pages/survey/index.vue?macro=true";
import { default as introVnMojZLDjVMeta } from "/app/src/pages/survey/intro.vue?macro=true";
import { default as invalid_45licenseUkY2jiBwMAMeta } from "/app/src/pages/survey/invalid-license.vue?macro=true";
import { default as pillar_45resultfUTiGYYcUdMeta } from "/app/src/pages/survey/pillar-result.vue?macro=true";
import { default as questionABbUB98WWtMeta } from "/app/src/pages/survey/question.vue?macro=true";
import { default as videot2XY0ytwwFMeta } from "/app/src/pages/survey/video.vue?macro=true";
import { default as surveyXzfEaQDFqvMeta } from "/app/src/pages/survey.vue?macro=true";
import { default as technical_45support4HHq3izVNqMeta } from "/app/src/pages/technical-support.vue?macro=true";
import { default as terms_and_conditionsWXM63hWSdsMeta } from "/common_fe/src/pages/terms_and_conditions.vue?macro=true";
export default [
  {
    name: companytWIBTdJjXhMeta?.name ?? undefined,
    path: companytWIBTdJjXhMeta?.path ?? "/company",
    meta: companytWIBTdJjXhMeta || {},
    alias: companytWIBTdJjXhMeta?.alias || [],
    redirect: companytWIBTdJjXhMeta?.redirect,
    component: () => import("/app/src/pages/company.vue").then(m => m.default || m),
    children: [
  {
    name: departmentsqdqOxAwHffMeta?.name ?? "company-departments___en",
    path: departmentsqdqOxAwHffMeta?.path ?? "departments-settings",
    meta: departmentsqdqOxAwHffMeta || {},
    alias: departmentsqdqOxAwHffMeta?.alias || [],
    redirect: departmentsqdqOxAwHffMeta?.redirect,
    component: () => import("/app/src/pages/company/departments.vue").then(m => m.default || m)
  },
  {
    name: employee_45settingsiBotCfaBDfMeta?.name ?? "company-employee-settings___en",
    path: employee_45settingsiBotCfaBDfMeta?.path ?? "employee-settings",
    meta: employee_45settingsiBotCfaBDfMeta || {},
    alias: employee_45settingsiBotCfaBDfMeta?.alias || [],
    redirect: employee_45settingsiBotCfaBDfMeta?.redirect,
    component: () => import("/app/src/pages/company/employee-settings.vue").then(m => m.default || m)
  },
  {
    name: indexPbDDEhGvAcMeta?.name ?? "company___en",
    path: indexPbDDEhGvAcMeta?.path ?? "",
    meta: indexPbDDEhGvAcMeta || {},
    alias: indexPbDDEhGvAcMeta?.alias || [],
    redirect: indexPbDDEhGvAcMeta?.redirect,
    component: () => import("/app/src/pages/company/index.vue").then(m => m.default || m)
  },
  {
    name: resultsRwGSehcFXsMeta?.name ?? "company-results___en",
    path: resultsRwGSehcFXsMeta?.path ?? "results",
    meta: resultsRwGSehcFXsMeta || {},
    alias: resultsRwGSehcFXsMeta?.alias || [],
    redirect: resultsRwGSehcFXsMeta?.redirect,
    component: () => import("/app/src/pages/company/results.vue").then(m => m.default || m)
  },
  {
    name: settingsu0Qj0yX5ewMeta?.name ?? "company-settings___en",
    path: settingsu0Qj0yX5ewMeta?.path ?? "settings",
    meta: settingsu0Qj0yX5ewMeta || {},
    alias: settingsu0Qj0yX5ewMeta?.alias || [],
    redirect: settingsu0Qj0yX5ewMeta?.redirect,
    component: () => import("/app/src/pages/company/settings.vue").then(m => m.default || m)
  }
]
  },
  {
    name: companytWIBTdJjXhMeta?.name ?? undefined,
    path: companytWIBTdJjXhMeta?.path ?? "/cs/spolecnost",
    meta: companytWIBTdJjXhMeta || {},
    alias: companytWIBTdJjXhMeta?.alias || [],
    redirect: companytWIBTdJjXhMeta?.redirect,
    component: () => import("/app/src/pages/company.vue").then(m => m.default || m),
    children: [
  {
    name: departmentsqdqOxAwHffMeta?.name ?? "company-departments___cs",
    path: departmentsqdqOxAwHffMeta?.path ?? "sprava-oddeleni",
    meta: departmentsqdqOxAwHffMeta || {},
    alias: departmentsqdqOxAwHffMeta?.alias || [],
    redirect: departmentsqdqOxAwHffMeta?.redirect,
    component: () => import("/app/src/pages/company/departments.vue").then(m => m.default || m)
  },
  {
    name: employee_45settingsiBotCfaBDfMeta?.name ?? "company-employee-settings___cs",
    path: employee_45settingsiBotCfaBDfMeta?.path ?? "sprava-zamestnancu",
    meta: employee_45settingsiBotCfaBDfMeta || {},
    alias: employee_45settingsiBotCfaBDfMeta?.alias || [],
    redirect: employee_45settingsiBotCfaBDfMeta?.redirect,
    component: () => import("/app/src/pages/company/employee-settings.vue").then(m => m.default || m)
  },
  {
    name: indexPbDDEhGvAcMeta?.name ?? "company___cs",
    path: indexPbDDEhGvAcMeta?.path ?? "",
    meta: indexPbDDEhGvAcMeta || {},
    alias: indexPbDDEhGvAcMeta?.alias || [],
    redirect: indexPbDDEhGvAcMeta?.redirect,
    component: () => import("/app/src/pages/company/index.vue").then(m => m.default || m)
  },
  {
    name: resultsRwGSehcFXsMeta?.name ?? "company-results___cs",
    path: resultsRwGSehcFXsMeta?.path ?? "vysledky",
    meta: resultsRwGSehcFXsMeta || {},
    alias: resultsRwGSehcFXsMeta?.alias || [],
    redirect: resultsRwGSehcFXsMeta?.redirect,
    component: () => import("/app/src/pages/company/results.vue").then(m => m.default || m)
  },
  {
    name: settingsu0Qj0yX5ewMeta?.name ?? "company-settings___cs",
    path: settingsu0Qj0yX5ewMeta?.path ?? "nastaveni",
    meta: settingsu0Qj0yX5ewMeta || {},
    alias: settingsu0Qj0yX5ewMeta?.alias || [],
    redirect: settingsu0Qj0yX5ewMeta?.redirect,
    component: () => import("/app/src/pages/company/settings.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91token_93TfP2itXTLrMeta?.name ?? "forgotten_password-uid-token___en",
    path: _91token_93TfP2itXTLrMeta?.path ?? "/forgotten_password/:uid()/:token()",
    meta: _91token_93TfP2itXTLrMeta || {},
    alias: _91token_93TfP2itXTLrMeta?.alias || [],
    redirect: _91token_93TfP2itXTLrMeta?.redirect,
    component: () => import("/common_fe/src/pages/forgotten_password/[uid]/[token].vue").then(m => m.default || m)
  },
  {
    name: _91token_93TfP2itXTLrMeta?.name ?? "forgotten_password-uid-token___cs",
    path: _91token_93TfP2itXTLrMeta?.path ?? "/cs/zapomenute_heslo/:uid()/:token()",
    meta: _91token_93TfP2itXTLrMeta || {},
    alias: _91token_93TfP2itXTLrMeta?.alias || [],
    redirect: _91token_93TfP2itXTLrMeta?.redirect,
    component: () => import("/common_fe/src/pages/forgotten_password/[uid]/[token].vue").then(m => m.default || m)
  },
  {
    name: index8GQijcUZslMeta?.name ?? "forgotten_password___en",
    path: index8GQijcUZslMeta?.path ?? "/forgotten_password",
    meta: index8GQijcUZslMeta || {},
    alias: index8GQijcUZslMeta?.alias || [],
    redirect: index8GQijcUZslMeta?.redirect,
    component: () => import("/common_fe/src/pages/forgotten_password/index.vue").then(m => m.default || m)
  },
  {
    name: index8GQijcUZslMeta?.name ?? "forgotten_password___cs",
    path: index8GQijcUZslMeta?.path ?? "/cs/zapomenute_heslo",
    meta: index8GQijcUZslMeta || {},
    alias: index8GQijcUZslMeta?.alias || [],
    redirect: index8GQijcUZslMeta?.redirect,
    component: () => import("/common_fe/src/pages/forgotten_password/index.vue").then(m => m.default || m)
  },
  {
    name: indexdPwi6hPdMfMeta?.name ?? "index___en",
    path: indexdPwi6hPdMfMeta?.path ?? "/",
    meta: indexdPwi6hPdMfMeta || {},
    alias: indexdPwi6hPdMfMeta?.alias || [],
    redirect: indexdPwi6hPdMfMeta?.redirect,
    component: () => import("/app/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexdPwi6hPdMfMeta?.name ?? "index___cs",
    path: indexdPwi6hPdMfMeta?.path ?? "/cs",
    meta: indexdPwi6hPdMfMeta || {},
    alias: indexdPwi6hPdMfMeta?.alias || [],
    redirect: indexdPwi6hPdMfMeta?.redirect,
    component: () => import("/app/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexSG0VHSSa7FMeta?.name ?? "login___en",
    path: indexSG0VHSSa7FMeta?.path ?? "/login",
    meta: indexSG0VHSSa7FMeta || {},
    alias: indexSG0VHSSa7FMeta?.alias || [],
    redirect: indexSG0VHSSa7FMeta?.redirect,
    component: () => import("/common_fe/src/pages/login/index.vue").then(m => m.default || m)
  },
  {
    name: indexSG0VHSSa7FMeta?.name ?? "login___cs",
    path: indexSG0VHSSa7FMeta?.path ?? "/cs/prihlaseni",
    meta: indexSG0VHSSa7FMeta || {},
    alias: indexSG0VHSSa7FMeta?.alias || [],
    redirect: indexSG0VHSSa7FMeta?.redirect,
    component: () => import("/common_fe/src/pages/login/index.vue").then(m => m.default || m)
  },
  {
    name: mental_45health_45careLwRuqHTCHyMeta?.name ?? "mental-health-care___en",
    path: mental_45health_45careLwRuqHTCHyMeta?.path ?? "/mental-health-care",
    meta: mental_45health_45careLwRuqHTCHyMeta || {},
    alias: mental_45health_45careLwRuqHTCHyMeta?.alias || [],
    redirect: mental_45health_45careLwRuqHTCHyMeta?.redirect,
    component: () => import("/app/src/pages/mental-health-care.vue").then(m => m.default || m)
  },
  {
    name: mental_45health_45careLwRuqHTCHyMeta?.name ?? "mental-health-care___cs",
    path: mental_45health_45careLwRuqHTCHyMeta?.path ?? "/cs/pece-o-dusevni-zdravi",
    meta: mental_45health_45careLwRuqHTCHyMeta || {},
    alias: mental_45health_45careLwRuqHTCHyMeta?.alias || [],
    redirect: mental_45health_45careLwRuqHTCHyMeta?.redirect,
    component: () => import("/app/src/pages/mental-health-care.vue").then(m => m.default || m)
  },
  {
    name: my_45resultsmBgC3VpbnpMeta?.name ?? undefined,
    path: my_45resultsmBgC3VpbnpMeta?.path ?? "/my-results",
    meta: my_45resultsmBgC3VpbnpMeta || {},
    alias: my_45resultsmBgC3VpbnpMeta?.alias || [],
    redirect: my_45resultsmBgC3VpbnpMeta?.redirect,
    component: () => import("/app/src/pages/my-results.vue").then(m => m.default || m),
    children: [
  {
    name: here_45i_45amEgaYxNJ4k2Meta?.name ?? "my-results-here-i-am___en",
    path: here_45i_45amEgaYxNJ4k2Meta?.path ?? "here-i-am",
    meta: here_45i_45amEgaYxNJ4k2Meta || {},
    alias: here_45i_45amEgaYxNJ4k2Meta?.alias || [],
    redirect: here_45i_45amEgaYxNJ4k2Meta?.redirect,
    component: () => import("/app/src/pages/my-results/here-i-am.vue").then(m => m.default || m)
  },
  {
    name: how_45and_45what_45i_45do3jDuIiMZLJMeta?.name ?? "my-results-how-and-what-i-do___en",
    path: how_45and_45what_45i_45do3jDuIiMZLJMeta?.path ?? "how-and-what-i-do",
    meta: how_45and_45what_45i_45do3jDuIiMZLJMeta || {},
    alias: how_45and_45what_45i_45do3jDuIiMZLJMeta?.alias || [],
    redirect: how_45and_45what_45i_45do3jDuIiMZLJMeta?.redirect,
    component: () => import("/app/src/pages/my-results/how-and-what-i-do.vue").then(m => m.default || m)
  },
  {
    name: indexKKVElqLjMDMeta?.name ?? "my-results___en",
    path: indexKKVElqLjMDMeta?.path ?? "",
    meta: indexKKVElqLjMDMeta || {},
    alias: indexKKVElqLjMDMeta?.alias || [],
    redirect: indexKKVElqLjMDMeta?.redirect,
    component: () => import("/app/src/pages/my-results/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: my_45resultsmBgC3VpbnpMeta?.name ?? undefined,
    path: my_45resultsmBgC3VpbnpMeta?.path ?? "/cs/moje-vysledky",
    meta: my_45resultsmBgC3VpbnpMeta || {},
    alias: my_45resultsmBgC3VpbnpMeta?.alias || [],
    redirect: my_45resultsmBgC3VpbnpMeta?.redirect,
    component: () => import("/app/src/pages/my-results.vue").then(m => m.default || m),
    children: [
  {
    name: here_45i_45amEgaYxNJ4k2Meta?.name ?? "my-results-here-i-am___cs",
    path: here_45i_45amEgaYxNJ4k2Meta?.path ?? "tady-jsem",
    meta: here_45i_45amEgaYxNJ4k2Meta || {},
    alias: here_45i_45amEgaYxNJ4k2Meta?.alias || [],
    redirect: here_45i_45amEgaYxNJ4k2Meta?.redirect,
    component: () => import("/app/src/pages/my-results/here-i-am.vue").then(m => m.default || m)
  },
  {
    name: how_45and_45what_45i_45do3jDuIiMZLJMeta?.name ?? "my-results-how-and-what-i-do___cs",
    path: how_45and_45what_45i_45do3jDuIiMZLJMeta?.path ?? "jak-a-co-delam",
    meta: how_45and_45what_45i_45do3jDuIiMZLJMeta || {},
    alias: how_45and_45what_45i_45do3jDuIiMZLJMeta?.alias || [],
    redirect: how_45and_45what_45i_45do3jDuIiMZLJMeta?.redirect,
    component: () => import("/app/src/pages/my-results/how-and-what-i-do.vue").then(m => m.default || m)
  },
  {
    name: indexKKVElqLjMDMeta?.name ?? "my-results___cs",
    path: indexKKVElqLjMDMeta?.path ?? "",
    meta: indexKKVElqLjMDMeta || {},
    alias: indexKKVElqLjMDMeta?.alias || [],
    redirect: indexKKVElqLjMDMeta?.redirect,
    component: () => import("/app/src/pages/my-results/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: need_45helpVDIi4enQ7QMeta?.name ?? "need-help___en",
    path: need_45helpVDIi4enQ7QMeta?.path ?? "/need-help",
    meta: need_45helpVDIi4enQ7QMeta || {},
    alias: need_45helpVDIi4enQ7QMeta?.alias || [],
    redirect: need_45helpVDIi4enQ7QMeta?.redirect,
    component: () => import("/app/src/pages/need-help.vue").then(m => m.default || m)
  },
  {
    name: need_45helpVDIi4enQ7QMeta?.name ?? "need-help___cs",
    path: need_45helpVDIi4enQ7QMeta?.path ?? "/cs/potrebuji-pomoct",
    meta: need_45helpVDIi4enQ7QMeta || {},
    alias: need_45helpVDIi4enQ7QMeta?.alias || [],
    redirect: need_45helpVDIi4enQ7QMeta?.redirect,
    component: () => import("/app/src/pages/need-help.vue").then(m => m.default || m)
  },
  {
    name: payment_45finishWcM0sTYirzMeta?.name ?? "payment-finish___en",
    path: payment_45finishWcM0sTYirzMeta?.path ?? "/payment-finish",
    meta: payment_45finishWcM0sTYirzMeta || {},
    alias: payment_45finishWcM0sTYirzMeta?.alias || [],
    redirect: payment_45finishWcM0sTYirzMeta?.redirect,
    component: () => import("/app/src/pages/payment-finish.vue").then(m => m.default || m)
  },
  {
    name: payment_45finishWcM0sTYirzMeta?.name ?? "payment-finish___cs",
    path: payment_45finishWcM0sTYirzMeta?.path ?? "/cs/dokonceni-platby",
    meta: payment_45finishWcM0sTYirzMeta || {},
    alias: payment_45finishWcM0sTYirzMeta?.alias || [],
    redirect: payment_45finishWcM0sTYirzMeta?.redirect,
    component: () => import("/app/src/pages/payment-finish.vue").then(m => m.default || m)
  },
  {
    name: privacy_policy_selfpayer8jHSgAWRCJMeta?.name ?? "privacy_policy_selfpayer___en",
    path: privacy_policy_selfpayer8jHSgAWRCJMeta?.path ?? "/privacy-policy-selfpayer",
    meta: privacy_policy_selfpayer8jHSgAWRCJMeta || {},
    alias: privacy_policy_selfpayer8jHSgAWRCJMeta?.alias || [],
    redirect: privacy_policy_selfpayer8jHSgAWRCJMeta?.redirect,
    component: () => import("/common_fe/src/pages/privacy_policy_selfpayer.vue").then(m => m.default || m)
  },
  {
    name: privacy_policy_selfpayer8jHSgAWRCJMeta?.name ?? "privacy_policy_selfpayer___cs",
    path: privacy_policy_selfpayer8jHSgAWRCJMeta?.path ?? "/cs/ochrana-osobnich-udaju-samoplatce",
    meta: privacy_policy_selfpayer8jHSgAWRCJMeta || {},
    alias: privacy_policy_selfpayer8jHSgAWRCJMeta?.alias || [],
    redirect: privacy_policy_selfpayer8jHSgAWRCJMeta?.redirect,
    component: () => import("/common_fe/src/pages/privacy_policy_selfpayer.vue").then(m => m.default || m)
  },
  {
    name: privacy_policykyJiQYDx4HMeta?.name ?? "privacy_policy___en",
    path: privacy_policykyJiQYDx4HMeta?.path ?? "/privacy-policy",
    meta: privacy_policykyJiQYDx4HMeta || {},
    alias: privacy_policykyJiQYDx4HMeta?.alias || [],
    redirect: privacy_policykyJiQYDx4HMeta?.redirect,
    component: () => import("/common_fe/src/pages/privacy_policy.vue").then(m => m.default || m)
  },
  {
    name: privacy_policykyJiQYDx4HMeta?.name ?? "privacy_policy___cs",
    path: privacy_policykyJiQYDx4HMeta?.path ?? "/cs/ochrana-osobnich-udaju",
    meta: privacy_policykyJiQYDx4HMeta || {},
    alias: privacy_policykyJiQYDx4HMeta?.alias || [],
    redirect: privacy_policykyJiQYDx4HMeta?.redirect,
    component: () => import("/common_fe/src/pages/privacy_policy.vue").then(m => m.default || m)
  },
  {
    name: profileTx35vCKuH1Meta?.name ?? undefined,
    path: profileTx35vCKuH1Meta?.path ?? "/profile",
    meta: profileTx35vCKuH1Meta || {},
    alias: profileTx35vCKuH1Meta?.alias || [],
    redirect: profileTx35vCKuH1Meta?.redirect,
    component: () => import("/app/src/pages/profile.vue").then(m => m.default || m),
    children: [
  {
    name: anonymity_45settingsVsmDOJh1dzMeta?.name ?? "profile-anonymity-settings___en",
    path: anonymity_45settingsVsmDOJh1dzMeta?.path ?? "anonymity-settings",
    meta: anonymity_45settingsVsmDOJh1dzMeta || {},
    alias: anonymity_45settingsVsmDOJh1dzMeta?.alias || [],
    redirect: anonymity_45settingsVsmDOJh1dzMeta?.redirect,
    component: () => import("/app/src/pages/profile/anonymity-settings.vue").then(m => m.default || m)
  },
  {
    name: demography_45datazux458MXsHMeta?.name ?? "profile-demography-data___en",
    path: demography_45datazux458MXsHMeta?.path ?? "demography-data",
    meta: demography_45datazux458MXsHMeta || {},
    alias: demography_45datazux458MXsHMeta?.alias || [],
    redirect: demography_45datazux458MXsHMeta?.redirect,
    component: () => import("/app/src/pages/profile/demography-data.vue").then(m => m.default || m)
  },
  {
    name: indexSJNeMkG0qzMeta?.name ?? "profile___en",
    path: indexSJNeMkG0qzMeta?.path ?? "",
    meta: indexSJNeMkG0qzMeta || {},
    alias: indexSJNeMkG0qzMeta?.alias || [],
    redirect: indexSJNeMkG0qzMeta?.redirect,
    component: () => import("/app/src/pages/profile/index.vue").then(m => m.default || m)
  },
  {
    name: personal_45dataBcf6Sa37nWMeta?.name ?? "profile-personal-data___en",
    path: personal_45dataBcf6Sa37nWMeta?.path ?? "personal-data",
    meta: personal_45dataBcf6Sa37nWMeta || {},
    alias: personal_45dataBcf6Sa37nWMeta?.alias || [],
    redirect: personal_45dataBcf6Sa37nWMeta?.redirect,
    component: () => import("/app/src/pages/profile/personal-data.vue").then(m => m.default || m)
  },
  {
    name: security_45settings5OUm4Y7e5RMeta?.name ?? "profile-security-settings___en",
    path: security_45settings5OUm4Y7e5RMeta?.path ?? "security-settings",
    meta: security_45settings5OUm4Y7e5RMeta || {},
    alias: security_45settings5OUm4Y7e5RMeta?.alias || [],
    redirect: security_45settings5OUm4Y7e5RMeta?.redirect,
    component: () => import("/app/src/pages/profile/security-settings.vue").then(m => m.default || m)
  }
]
  },
  {
    name: profileTx35vCKuH1Meta?.name ?? undefined,
    path: profileTx35vCKuH1Meta?.path ?? "/cs/profil",
    meta: profileTx35vCKuH1Meta || {},
    alias: profileTx35vCKuH1Meta?.alias || [],
    redirect: profileTx35vCKuH1Meta?.redirect,
    component: () => import("/app/src/pages/profile.vue").then(m => m.default || m),
    children: [
  {
    name: anonymity_45settingsVsmDOJh1dzMeta?.name ?? "profile-anonymity-settings___cs",
    path: anonymity_45settingsVsmDOJh1dzMeta?.path ?? "nastaveni-soukromi",
    meta: anonymity_45settingsVsmDOJh1dzMeta || {},
    alias: anonymity_45settingsVsmDOJh1dzMeta?.alias || [],
    redirect: anonymity_45settingsVsmDOJh1dzMeta?.redirect,
    component: () => import("/app/src/pages/profile/anonymity-settings.vue").then(m => m.default || m)
  },
  {
    name: demography_45datazux458MXsHMeta?.name ?? "profile-demography-data___cs",
    path: demography_45datazux458MXsHMeta?.path ?? "demograficka-data",
    meta: demography_45datazux458MXsHMeta || {},
    alias: demography_45datazux458MXsHMeta?.alias || [],
    redirect: demography_45datazux458MXsHMeta?.redirect,
    component: () => import("/app/src/pages/profile/demography-data.vue").then(m => m.default || m)
  },
  {
    name: indexSJNeMkG0qzMeta?.name ?? "profile___cs",
    path: indexSJNeMkG0qzMeta?.path ?? "",
    meta: indexSJNeMkG0qzMeta || {},
    alias: indexSJNeMkG0qzMeta?.alias || [],
    redirect: indexSJNeMkG0qzMeta?.redirect,
    component: () => import("/app/src/pages/profile/index.vue").then(m => m.default || m)
  },
  {
    name: personal_45dataBcf6Sa37nWMeta?.name ?? "profile-personal-data___cs",
    path: personal_45dataBcf6Sa37nWMeta?.path ?? "osobni-udaje",
    meta: personal_45dataBcf6Sa37nWMeta || {},
    alias: personal_45dataBcf6Sa37nWMeta?.alias || [],
    redirect: personal_45dataBcf6Sa37nWMeta?.redirect,
    component: () => import("/app/src/pages/profile/personal-data.vue").then(m => m.default || m)
  },
  {
    name: security_45settings5OUm4Y7e5RMeta?.name ?? "profile-security-settings___cs",
    path: security_45settings5OUm4Y7e5RMeta?.path ?? "nastaveni-zabezpeceni",
    meta: security_45settings5OUm4Y7e5RMeta || {},
    alias: security_45settings5OUm4Y7e5RMeta?.alias || [],
    redirect: security_45settings5OUm4Y7e5RMeta?.redirect,
    component: () => import("/app/src/pages/profile/security-settings.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexflND2zZ1Z4Meta?.name ?? "registration___en",
    path: indexflND2zZ1Z4Meta?.path ?? "/registration",
    meta: indexflND2zZ1Z4Meta || {},
    alias: indexflND2zZ1Z4Meta?.alias || [],
    redirect: indexflND2zZ1Z4Meta?.redirect,
    component: () => import("/common_fe/src/pages/registration/index.vue").then(m => m.default || m)
  },
  {
    name: indexflND2zZ1Z4Meta?.name ?? "registration___cs",
    path: indexflND2zZ1Z4Meta?.path ?? "/cs/registrace",
    meta: indexflND2zZ1Z4Meta || {},
    alias: indexflND2zZ1Z4Meta?.alias || [],
    redirect: indexflND2zZ1Z4Meta?.redirect,
    component: () => import("/common_fe/src/pages/registration/index.vue").then(m => m.default || m)
  },
  {
    name: surveyXzfEaQDFqvMeta?.name ?? undefined,
    path: surveyXzfEaQDFqvMeta?.path ?? "/assessment",
    meta: surveyXzfEaQDFqvMeta || {},
    alias: surveyXzfEaQDFqvMeta?.alias || [],
    redirect: surveyXzfEaQDFqvMeta?.redirect,
    component: () => import("/app/src/pages/survey.vue").then(m => m.default || m),
    children: [
  {
    name: demographyjJ2p5TnCZ7Meta?.name ?? "survey-demography___en",
    path: demographyjJ2p5TnCZ7Meta?.path ?? "demography",
    meta: demographyjJ2p5TnCZ7Meta || {},
    alias: demographyjJ2p5TnCZ7Meta?.alias || [],
    redirect: demographyjJ2p5TnCZ7Meta?.redirect,
    component: () => import("/app/src/pages/survey/demography.vue").then(m => m.default || m)
  },
  {
    name: dimension_45resultGraBCGuXCIMeta?.name ?? "survey-dimension-result___en",
    path: dimension_45resultGraBCGuXCIMeta?.path ?? "dimension-result",
    meta: dimension_45resultGraBCGuXCIMeta || {},
    alias: dimension_45resultGraBCGuXCIMeta?.alias || [],
    redirect: dimension_45resultGraBCGuXCIMeta?.redirect,
    component: () => import("/app/src/pages/survey/dimension-result.vue").then(m => m.default || m)
  },
  {
    name: finishhzIbu2ifTTMeta?.name ?? "survey-finish___en",
    path: finishhzIbu2ifTTMeta?.path ?? "finish",
    meta: finishhzIbu2ifTTMeta || {},
    alias: finishhzIbu2ifTTMeta?.alias || [],
    redirect: finishhzIbu2ifTTMeta?.redirect,
    component: () => import("/app/src/pages/survey/finish.vue").then(m => m.default || m)
  },
  {
    name: indexzKW1J69z7OMeta?.name ?? "survey___en",
    path: indexzKW1J69z7OMeta?.path ?? "/assessment",
    meta: indexzKW1J69z7OMeta || {},
    alias: indexzKW1J69z7OMeta?.alias || [],
    redirect: indexzKW1J69z7OMeta?.redirect,
    component: () => import("/app/src/pages/survey/index.vue").then(m => m.default || m)
  },
  {
    name: introVnMojZLDjVMeta?.name ?? "survey-intro___en",
    path: introVnMojZLDjVMeta?.path ?? "intro",
    meta: introVnMojZLDjVMeta || {},
    alias: introVnMojZLDjVMeta?.alias || [],
    redirect: introVnMojZLDjVMeta?.redirect,
    component: () => import("/app/src/pages/survey/intro.vue").then(m => m.default || m)
  },
  {
    name: invalid_45licenseUkY2jiBwMAMeta?.name ?? "survey-invalid-license___en",
    path: invalid_45licenseUkY2jiBwMAMeta?.path ?? "invalid-license",
    meta: invalid_45licenseUkY2jiBwMAMeta || {},
    alias: invalid_45licenseUkY2jiBwMAMeta?.alias || [],
    redirect: invalid_45licenseUkY2jiBwMAMeta?.redirect,
    component: () => import("/app/src/pages/survey/invalid-license.vue").then(m => m.default || m)
  },
  {
    name: pillar_45resultfUTiGYYcUdMeta?.name ?? "survey-pillar-result___en",
    path: pillar_45resultfUTiGYYcUdMeta?.path ?? "pillar-result",
    meta: pillar_45resultfUTiGYYcUdMeta || {},
    alias: pillar_45resultfUTiGYYcUdMeta?.alias || [],
    redirect: pillar_45resultfUTiGYYcUdMeta?.redirect,
    component: () => import("/app/src/pages/survey/pillar-result.vue").then(m => m.default || m)
  },
  {
    name: questionABbUB98WWtMeta?.name ?? "survey-question___en",
    path: questionABbUB98WWtMeta?.path ?? "question",
    meta: questionABbUB98WWtMeta || {},
    alias: questionABbUB98WWtMeta?.alias || [],
    redirect: questionABbUB98WWtMeta?.redirect,
    component: () => import("/app/src/pages/survey/question.vue").then(m => m.default || m)
  },
  {
    name: videot2XY0ytwwFMeta?.name ?? "survey-video___en",
    path: videot2XY0ytwwFMeta?.path ?? "video",
    meta: videot2XY0ytwwFMeta || {},
    alias: videot2XY0ytwwFMeta?.alias || [],
    redirect: videot2XY0ytwwFMeta?.redirect,
    component: () => import("/app/src/pages/survey/video.vue").then(m => m.default || m)
  }
]
  },
  {
    name: surveyXzfEaQDFqvMeta?.name ?? undefined,
    path: surveyXzfEaQDFqvMeta?.path ?? "/cs/hodnoceni",
    meta: surveyXzfEaQDFqvMeta || {},
    alias: surveyXzfEaQDFqvMeta?.alias || [],
    redirect: surveyXzfEaQDFqvMeta?.redirect,
    component: () => import("/app/src/pages/survey.vue").then(m => m.default || m),
    children: [
  {
    name: demographyjJ2p5TnCZ7Meta?.name ?? "survey-demography___cs",
    path: demographyjJ2p5TnCZ7Meta?.path ?? "demography",
    meta: demographyjJ2p5TnCZ7Meta || {},
    alias: demographyjJ2p5TnCZ7Meta?.alias || [],
    redirect: demographyjJ2p5TnCZ7Meta?.redirect,
    component: () => import("/app/src/pages/survey/demography.vue").then(m => m.default || m)
  },
  {
    name: dimension_45resultGraBCGuXCIMeta?.name ?? "survey-dimension-result___cs",
    path: dimension_45resultGraBCGuXCIMeta?.path ?? "vysledek-dimenze",
    meta: dimension_45resultGraBCGuXCIMeta || {},
    alias: dimension_45resultGraBCGuXCIMeta?.alias || [],
    redirect: dimension_45resultGraBCGuXCIMeta?.redirect,
    component: () => import("/app/src/pages/survey/dimension-result.vue").then(m => m.default || m)
  },
  {
    name: finishhzIbu2ifTTMeta?.name ?? "survey-finish___cs",
    path: finishhzIbu2ifTTMeta?.path ?? "konec",
    meta: finishhzIbu2ifTTMeta || {},
    alias: finishhzIbu2ifTTMeta?.alias || [],
    redirect: finishhzIbu2ifTTMeta?.redirect,
    component: () => import("/app/src/pages/survey/finish.vue").then(m => m.default || m)
  },
  {
    name: indexzKW1J69z7OMeta?.name ?? "survey___cs",
    path: indexzKW1J69z7OMeta?.path ?? "/cs/hodnoceni",
    meta: indexzKW1J69z7OMeta || {},
    alias: indexzKW1J69z7OMeta?.alias || [],
    redirect: indexzKW1J69z7OMeta?.redirect,
    component: () => import("/app/src/pages/survey/index.vue").then(m => m.default || m)
  },
  {
    name: introVnMojZLDjVMeta?.name ?? "survey-intro___cs",
    path: introVnMojZLDjVMeta?.path ?? "uvod",
    meta: introVnMojZLDjVMeta || {},
    alias: introVnMojZLDjVMeta?.alias || [],
    redirect: introVnMojZLDjVMeta?.redirect,
    component: () => import("/app/src/pages/survey/intro.vue").then(m => m.default || m)
  },
  {
    name: invalid_45licenseUkY2jiBwMAMeta?.name ?? "survey-invalid-license___cs",
    path: invalid_45licenseUkY2jiBwMAMeta?.path ?? "invalid-license",
    meta: invalid_45licenseUkY2jiBwMAMeta || {},
    alias: invalid_45licenseUkY2jiBwMAMeta?.alias || [],
    redirect: invalid_45licenseUkY2jiBwMAMeta?.redirect,
    component: () => import("/app/src/pages/survey/invalid-license.vue").then(m => m.default || m)
  },
  {
    name: pillar_45resultfUTiGYYcUdMeta?.name ?? "survey-pillar-result___cs",
    path: pillar_45resultfUTiGYYcUdMeta?.path ?? "vysledek-pilire",
    meta: pillar_45resultfUTiGYYcUdMeta || {},
    alias: pillar_45resultfUTiGYYcUdMeta?.alias || [],
    redirect: pillar_45resultfUTiGYYcUdMeta?.redirect,
    component: () => import("/app/src/pages/survey/pillar-result.vue").then(m => m.default || m)
  },
  {
    name: questionABbUB98WWtMeta?.name ?? "survey-question___cs",
    path: questionABbUB98WWtMeta?.path ?? "otazka",
    meta: questionABbUB98WWtMeta || {},
    alias: questionABbUB98WWtMeta?.alias || [],
    redirect: questionABbUB98WWtMeta?.redirect,
    component: () => import("/app/src/pages/survey/question.vue").then(m => m.default || m)
  },
  {
    name: videot2XY0ytwwFMeta?.name ?? "survey-video___cs",
    path: videot2XY0ytwwFMeta?.path ?? "video",
    meta: videot2XY0ytwwFMeta || {},
    alias: videot2XY0ytwwFMeta?.alias || [],
    redirect: videot2XY0ytwwFMeta?.redirect,
    component: () => import("/app/src/pages/survey/video.vue").then(m => m.default || m)
  }
]
  },
  {
    name: technical_45support4HHq3izVNqMeta?.name ?? "technical-support___en",
    path: technical_45support4HHq3izVNqMeta?.path ?? "/technical-support",
    meta: technical_45support4HHq3izVNqMeta || {},
    alias: technical_45support4HHq3izVNqMeta?.alias || [],
    redirect: technical_45support4HHq3izVNqMeta?.redirect,
    component: () => import("/app/src/pages/technical-support.vue").then(m => m.default || m)
  },
  {
    name: technical_45support4HHq3izVNqMeta?.name ?? "technical-support___cs",
    path: technical_45support4HHq3izVNqMeta?.path ?? "/cs/technicka-podpora",
    meta: technical_45support4HHq3izVNqMeta || {},
    alias: technical_45support4HHq3izVNqMeta?.alias || [],
    redirect: technical_45support4HHq3izVNqMeta?.redirect,
    component: () => import("/app/src/pages/technical-support.vue").then(m => m.default || m)
  },
  {
    name: terms_and_conditionsWXM63hWSdsMeta?.name ?? "terms_and_conditions___en",
    path: terms_and_conditionsWXM63hWSdsMeta?.path ?? "/terms-and-conditions",
    meta: terms_and_conditionsWXM63hWSdsMeta || {},
    alias: terms_and_conditionsWXM63hWSdsMeta?.alias || [],
    redirect: terms_and_conditionsWXM63hWSdsMeta?.redirect,
    component: () => import("/common_fe/src/pages/terms_and_conditions.vue").then(m => m.default || m)
  },
  {
    name: terms_and_conditionsWXM63hWSdsMeta?.name ?? "terms_and_conditions___cs",
    path: terms_and_conditionsWXM63hWSdsMeta?.path ?? "/cs/vseobecne-obchodni-podminky",
    meta: terms_and_conditionsWXM63hWSdsMeta || {},
    alias: terms_and_conditionsWXM63hWSdsMeta?.alias || [],
    redirect: terms_and_conditionsWXM63hWSdsMeta?.redirect,
    component: () => import("/common_fe/src/pages/terms_and_conditions.vue").then(m => m.default || m)
  }
]