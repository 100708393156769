import { acceptHMRUpdate, defineStore } from "pinia";
import * as Sentry from "@sentry/vue";

export const useUserInfoStore = defineStore("userInfoStore", () => {
  const apiUrls = useCommonApiUrls();

  const userData = reactive<UserInfoData>({
    username: null,
    first_name: null,
    last_name: null,
    language: null,
    address_by: null,
    is_company_admin: null,
    company_id: null,
    mfa_method: null,
    is_superuser: null,
    is_account_manager: null,
    is_account_manager_supervisor: null,
    is_self_payer: null
  });

  const isDataLoaded = ref<boolean>(false);
  const dataSaving = ref<boolean>(false);

  async function loadUserInfoData () {
    const { data } = await useApiCall<UserInfoData>(apiUrls.USER_INFO());
    if (data.value) {
      Object.assign(userData, data.value);
      isDataLoaded.value = true;
      Sentry.setUser({ username: userData.username as string });
    }
  }

  async function saveUserInfoData () {
    dataSaving.value = true;
    isDataLoaded.value = false;
    const { data } = await useApiCall<UserInfoData>(apiUrls.USER_INFO(), { method: "PATCH", body: userData, key: JSON.stringify(userData) });
    if (data.value) {
      Object.assign(userData, data.value);
      isDataLoaded.value = true;
    }
    dataSaving.value = false;
  }

  const userName = computed<string>(() => { return `${userData.first_name} ${userData.last_name}`; });

  return {
    userData,
    userName,
    isDataLoaded,
    dataSaving,
    loadUserInfoData,
    saveUserInfoData
  };
});

// make sure to pass the right store definition
if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useUserInfoStore, import.meta.hot));
}
