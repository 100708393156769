import { createVuetify } from "vuetify";
import type { ThemeDefinition } from "vuetify";
// import { md3 } from "vuetify/blueprints";
import { cs, en } from "vuetify/locale";
// @ts-ignore
import VueTelInput from "vue-tel-input"; // TODO: use @types/vue-tel-input when its updated
import "vue-tel-input/vue-tel-input.css";

const globalOptions = {
  mode: "auto"
};

const commonColors = {
  green: "#8bc34a",
  red: "#f44336",
  info: "#7460ee",
  success: "#0acc95",
  accent: "#fc4b6c",
  warning: "#fec90f",
  error: "#ef5350",
  barChartGreenStart: "#C1D80F",
  barChartGreenEnd: "#13B33A",
  barChartRedStart: "#FFA800",
  barChartRedEnd: "#FF005C",
  barChartBorder: "#85A9C0",
  pieChartGreenStart: "#1BB438",
  pieChartGreenEnd: "#1BD944",
  pieChartRedStart: "#CC003D",
  pieChartRedEnd: "#FF0D55"
};

export default defineNuxtPlugin((nuxtApp) => {
  const LightTheme: ThemeDefinition = {
    dark: false,
    colors: {
      ...commonColors,
      primary: "#fff",
      secondary: "#0acc95",
      primaryInverse: "#202020",
      navbarDropdown: "#2B2B2C",
      activeBorder: "#46a3b4",
      btnSecondary: "#c9c9c9",
      border: "#9e9e9e",
      btnLight: "#fff",
      textMuted: "#85A9C0",
      bgLight: "#D7E9FB",
      bgGreyLight: "#f5f5f5"

    }
  };

  const DarkTheme: ThemeDefinition = {
    dark: true,
    colors: {
      ...commonColors,
      background: "#161616",
      surface: "#2b2b2b",
      primary: "#202020",
      secondary: "#0acc95",
      primaryInverse: "#fff",
      border: "#9e9e9e",
      navbarDropdown: "#454545",
      activeBorder: "#99c8d2",
      btnSecondary: "#c9c9c9",
      btnLight: "#454545",
      textMuted: "#85A9C0",
      bgLight: "#454545",
      bgGreyLight: "#454545"
    }
  };

  const vuetify = createVuetify({
    ssr: false,
    theme: {
      defaultTheme: "dark",
      themes: {
        light: LightTheme,
        dark: DarkTheme
      }
    },
    locale: {
      locale: "cs",
      fallback: "en",
      messages: { cs, en }
    },
    display: {
      mobileBreakpoint: "sm",
      thresholds: {
        xs: 0,
        sm: 375,
        md: 576,
        lg: 992,
        xl: 1200,
        xxl: 1440
      }
    },
    // blueprint: md3,
    defaults: {
      VBtn: {
        color: "primary",
        rounded: true,
        fontWeight: "400",
        letterSpacing: "0",
        density: "default"
      },
      VTab: {
        rounded: false
      },
      VCard: {
        flat: true,
        elevation: 0
      },
      VAutocomplete: {
        variant: "outlined"
      },
      VTextField: {
        variant: "outlined"
      }
    }
  });

  nuxtApp.vueApp.use(vuetify);
  nuxtApp.vueApp.use(VueTelInput, globalOptions);
});
